import React, {useCallback, useEffect, useState} from 'react';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import PropTypes from 'prop-types';
import styles from '../common/List.theme.scss';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import KiInput from '../../../../components/KiInput';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import _debounce from 'lodash/debounce';
import KiSelect from '../../../../components/KiSelect';
import NewItemSection from '../common/NewItemSection';
import ListUpdatedAtCell from '../common/ListUpdatedAtCell';
import ListActionsCell from '../common/ListActionsCell';
import {getAssumptions, deleteAssumption, getAssumptionDeps} from '../../../../api/waterfallApi';
import {showSnackbar} from '../../../../state/actions/Snackbar';
import {connect} from 'react-redux';
/* eslint-disable react/display-name, react/prop-types */

export const ASSUMPTION_PROP_TO_TYPE = {
	cashFlowState: 'Cashflow',
	collateralState: 'Collateral',
	interestRateState: 'Interest Rates',
	delinquencyState: 'Delinquency',
};

const TYPE_OPTIONS = Object.entries(ASSUMPTION_PROP_TO_TYPE).map(([key, value]) => ({value: key, label: value}));

const AssumptionList = ({setFlyoutMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fullAssumptionList, setFullAssumptionList] = useState([]);
	const [currentAssumptions, setCurrentAssumptions] = useState([]);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [typeFilter, setTypeFilter] = useState([]);
	const [deletionTarget, setDeletionTarget] = useState();
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [assumptionDeps, setAssumptionDeps] = useState([]);

	const filterAssumptions = (phrase, types, fullList) => {
		let filteredList = fullList;
		if (phrase) {
			filteredList = filteredList.filter(item => item.name.toLowerCase().includes(phrase.toLowerCase()));
		}
		if (types && types.length > 0) {
			filteredList = filteredList.filter(item =>
				types.map(type => item[type]).reduce((acc, curr) => acc && curr)
			);
		}

		setCurrentAssumptions(filteredList);
	};

	const loadData = () => {
		setIsLoading(true);
		getAssumptions().then(data => {
			setFullAssumptionList(data);
			filterAssumptions(searchPhrase, typeFilter, data);
			setIsLoading(false);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				enableSorting: true,
				isSorted: true,
				id: 'name',
			},
			{
				Header: 'Type',
				enableSorting: false,
				Cell: ({row}) => {
					const assumption = row.original;
					const types = Object.entries(ASSUMPTION_PROP_TO_TYPE)
						.map(([key, value]) => (assumption[key] ? value : null))
						.filter(item => !!item);
					const typesToString = types.length > 0 ? types.join(', ') : 'None';
					return <span title={typesToString}>{typesToString}</span>;
				},
			},
			{
				Header: 'Updated',
				accessor: 'lastUpdated',
				enableSorting: true,
				Cell: ({value}) => <ListUpdatedAtCell value={value} />,
			},
			{
				Header: '',
				id: 'actions',
				Cell: ({row}) => (
					<ListActionsCell
						actions={[
							{
								title: 'Edit',
								icon: 'edit',
								callback: () => setFlyoutMode(FORECASTING_FLYOUT_MODE.ASSUMPTION_ADD, row.original._id),
							},
							{title: 'Delete', icon: 'delete', callback: () => setDeletionTarget(row.original)},
						]}
					/>
				),
				enableSorting: false,
				width: 76,
			},
		],
		[]
	);

	const filterAssumptionsDebounce = useCallback(
		_debounce((phrase, types) => filterAssumptions(phrase, types, fullAssumptionList), 500),
		[fullAssumptionList]
	);

	const onSearch = phrase => {
		setSearchPhrase(phrase);
		filterAssumptionsDebounce(phrase, typeFilter);
	};

	const onDeleteConfirmation = () => {
		setIsLoading(true);
		getAssumptionDeps(deletionTarget._id).then(result => {
			if (Array.isArray(result) && result.length > 0) {
				setAssumptionDeps(result);
				setDeleteReferentialMsgActive(true);
				setDeletionTarget(undefined);
				setIsLoading(false);
			} else {
				deleteAssumption(deletionTarget._id).then(() => {
					setDeletionTarget(null);
					showSnackbar('Assumption deleted successfully');
					loadData();
				});
			}
		});
	};

	const onTypeFilterChange = newTypes => {
		setTypeFilter(newTypes);
		filterAssumptionsDebounce(searchPhrase, newTypes);
	};

	return (
		<>
			<NewItemSection label="Assumption" onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.ASSUMPTION_ADD)} />
			<section className={styles.filters}>
				<div>
					<KiInput label="Search Assumptions" onChange={onSearch} value={searchPhrase} />
				</div>
				<div className={styles.bottomPadding}>
					<KiSelect
						isMulti
						options={TYPE_OPTIONS}
						onChange={selected =>
							onTypeFilterChange(Array.isArray(selected) ? selected.map(x => x.value) : [])
						}
						value={TYPE_OPTIONS.filter(option => typeFilter.includes(option.value))}
						placeholder="Filter by Type"
					/>
				</div>
			</section>
			<AbsTable data={currentAssumptions} columns={columns} isLoading={isLoading} isFilterEnabled={false} />
			<KiConfirmModal
				id="AssumptionDeletionConfirmModal"
				header="Delete Assumption"
				message={`Are you sure you want to delete the "${deletionTarget?.name}"?`}
				acceptFunc={onDeleteConfirmation}
				rejectFunc={() => setDeletionTarget(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!deletionTarget}
			/>
			<KiConfirmModal
				active={deleteReferentialMsgActive}
				acceptDisabled={true}
				rejectFunc={() => setDeleteReferentialMsgActive(false)}
				rejectLabel="OK"
				header="Cannot delete"
				message="The assumption could not be deleted because it is used in the following replines"
			>
				{assumptionDeps.map(c => {
					return <li key={c._id}>{c.name}</li>;
				})}
			</KiConfirmModal>
		</>
	);
};

AssumptionList.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(
	null,
	{showSnackbar}
)(AssumptionList);
