import React, {useCallback, useEffect, useState} from 'react';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import PropTypes from 'prop-types';
import styles from '../common/List.theme.scss';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import KiInput from '../../../../components/KiInput';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import _debounce from 'lodash/debounce';
import NewItemSection from '../common/NewItemSection';
import ListActionsCell from '../common/ListActionsCell';
import ListUpdatedAtCell from '../common/ListUpdatedAtCell';
import {getVectorDeps, getVectors, deleteVector} from '../../../../api/waterfallApi';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';
import VectorListMainCell from './components/VectorListMainCell';
import {vectorTypeOptions} from 'ki-common/options/assumptions';
/* eslint-disable react/display-name, react/prop-types */

const VectorList = ({setFlyoutMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fullVectorList, setFullVectorList] = useState([]);
	const [currentVectors, setCurrentVectors] = useState([]);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [deletionTarget, setDeletionTarget] = useState();
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [vectorDeps, setVectorDeps] = useState([]);

	const filterVectors = (phrase, fullList) =>
		setCurrentVectors(fullList.filter(item => item.name.toLowerCase().includes(phrase.toLowerCase())));

	const loadData = () => {
		setIsLoading(true);
		getVectors().then(vectors => {
			setFullVectorList(vectors);
			filterVectors(searchPhrase, vectors);
			setIsLoading(false);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				id: 'name',
				sortable: true,
				isSorted: true,
				Cell: ({row}) => <VectorListMainCell vector={row.original} />,
				tdClassName: styles.additionalDataTd,
			},
			{
				Header: 'Type',
				accessor: 'type',
				sortable: true,
				Cell: ({value}) => vectorTypeOptions.find(option => option.value === value)?.label,
				tdClassName: styles.additionalDataTdSibling,
			},
			{
				Header: 'Updated',
				accessor: 'lastUpdated',
				sortable: true,
				Cell: ({value}) => <ListUpdatedAtCell value={value} />,
				tdClassName: styles.additionalDataTdSibling,
			},
			{
				Header: '',
				id: 'actions',
				Cell: ({row}) => (
					<ListActionsCell
						actions={[
							{
								title: 'Copy',
								icon: 'content_copy',
								callback: () =>
									setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_ADD, row.original._id, true),
							},
							{
								title: 'Edit',
								icon: 'edit',
								callback: () => setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_ADD, row.original._id),
							},
							{title: 'Delete', icon: 'delete', callback: () => setDeletionTarget(row.original)},
						]}
					/>
				),
				sortable: false,
				width: 114,
				tdClassName: styles.additionalDataTdActions,
			},
		],
		[]
	);

	const filterVectorsDebounce = useCallback(_debounce(phrase => filterVectors(phrase, fullVectorList), 500), [
		fullVectorList,
	]);

	const onSearch = phrase => {
		setSearchPhrase(phrase);
		filterVectorsDebounce(phrase);
	};

	const onDeleteConfirmation = () => {
		setIsLoading(true);
		getVectorDeps(deletionTarget._id).then(result => {
			if (Array.isArray(result) && result.length > 0) {
				setVectorDeps(result);
				setDeleteReferentialMsgActive(true);
				setDeletionTarget(null);
				setIsLoading(false);
			} else {
				setIsLoading(true);
				deleteVector(deletionTarget._id).then(() => {
					setDeletionTarget(null);
					showSnackbar('Vector deleted successfully');
					loadData();
				});
			}
		});
	};

	return (
		<>
			<NewItemSection label="Vector" onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_ADD)} />
			<section className={styles.filters}>
				<div>
					<KiInput label="Search Vectors" onChange={onSearch} value={searchPhrase} />
				</div>
			</section>
			<AbsTable data={currentVectors} columns={columns} isLoading={isLoading} isFilterEnabled={false} />
			<KiConfirmModal
				id="VectorDeletionConfirmModal"
				header="Delete Vector"
				message={`Are you sure you want to delete the "${deletionTarget?.name}"?`}
				acceptFunc={onDeleteConfirmation}
				rejectFunc={() => setDeletionTarget(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!deletionTarget}
			/>
			<KiConfirmModal
				active={deleteReferentialMsgActive}
				acceptDisabled={true}
				rejectFunc={() => setDeleteReferentialMsgActive(false)}
				rejectLabel="OK"
				header="Cannot delete"
				message="The vector could not be deleted because it is used in the following assumptions"
			>
				<ul>
					{vectorDeps.map(c => {
						return <li key={c._id}>{c.name}</li>;
					})}
				</ul>
			</KiConfirmModal>
		</>
	);
};

VectorList.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(
	null,
	{showSnackbar}
)(VectorList);
