import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import {format} from 'ki-common/utils/displayFormatter';
import {containsBusinessFunctionColumn} from 'ki-common/utils/columnSearchUtil';
import bfData from 'ki-common/businessFunctions';
import KiFontIcon from 'components/KiFontIcon';
import styles from './defaultMappings.theme.scss';
import Select from 'react-select';

export class DefaultMappingRow extends Component {
	static propTypes = {
		assetColumns: PropTypes.array.isRequired,
		mapping: PropTypes.object.isRequired,
		updateFunction: PropTypes.func.isRequired,
	};

	static defaultProps = {
		assetColumns: [],
	};

	state = {};

	componentDidUpdate = prevProps => {
		if (!_isEqual(this.props, prevProps)) {
			this.setState({mappedColumnId: this.props.mapping.columnId});
		}
	};

	isActive = () => {
		return this.state.mappedColumnId && this.props.mapping.columnId !== this.state.mappedColumnId;
	};

	handleUndo = () => {
		if (!this.isActive()) return null;
		this.setState({mappedColumnId: this.props.mapping.columnId});
	};

	handleSave = () => {
		if (!this.isActive()) return null;
		// Clean up the mapping so it is only the base fields
		const newMapping = _cloneDeep(this.props.mapping);
		newMapping.createdBy = newMapping.createdBy.userId;
		newMapping.modifiedBy = newMapping.modifiedBy.userId;
		delete newMapping.functionList;
		newMapping.columnId = this.state.mappedColumnId;
		this.props.updateFunction(`${newMapping._id}`, newMapping);
	};

	render() {
		const {assetColumns, mapping} = this.props;
		const created = `${mapping.createdBy} ${format(mapping.createdDate, 'YYYY-MM-DD')}`;
		const modified = `${mapping.modifiedBy} ${format(mapping.modifiedDate, 'YYYY-MM-DD')}`;
		const selectColumns = assetColumns.filter(column => {
			return column.dataType === mapping.type && !containsBusinessFunctionColumn(assetColumns, column);
		});
		const isActive = this.isActive();
		const selectValue = this.state.mappedColumnId || mapping.columnId;

		// We are only rendering different options for interest accrual. This is also the only business function object in ki-common that has its own options on it.
		const interestAccrualOptions = bfData.businessVariableList.find(item => item.name === 'interestAccrual')
			.options;
		const hardcodeOptionsList = interestAccrualOptions.map(entry => ({
			value: entry.value,
			label: entry.label,
		}));

		return (
			<tr key={mapping._id}>
				<td>{mapping.displayName}</td>
				<td>
					{mapping.name !== 'interestAccrual' && (
						<Select
							classNamePrefix="aut-select"
							default="Select..."
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							isClearable={false}
							options={selectColumns}
							value={
								selectColumns.find(option => {
									return option._id === selectValue;
								}) || ''
							}
							className="ki-form-select-horizontal"
							onChange={val => this.setState({mappedColumnId: val._id || ''})}
						/>
					)}

					{/* There is only one exception for which to render different dropdown values, interestAccrual*/}
					{mapping.name === 'interestAccrual' && (
						<Select
							classNamePrefix="aut-select"
							isClearable={false}
							options={hardcodeOptionsList}
							value={
								hardcodeOptionsList.find(option => {
									return option.value === selectValue;
								}) || ''
							}
							className="ki-form-select-horizontal"
							onChange={val => this.setState({mappedColumnId: _get(val, 'value', '')})}
						/>
					)}
				</td>
				<td>
					<p>{mapping.type}</p>
				</td>
				<td>
					<p>{created}</p>
				</td>
				<td>
					<p>{modified}</p>
				</td>
				<td>
					<KiFontIcon
						value="undo"
						onClick={this.handleUndo}
						className={classNames([styles.icon], 'ki-icon-rollover', {'ki-icon-disabled': !isActive})}
					/>
					<KiFontIcon
						value="save"
						onClick={this.handleSave}
						className={classNames([styles.icon], 'ki-icon-rollover', {'ki-icon-disabled': !isActive})}
					/>
				</td>
			</tr>
		);
	}
}

export default DefaultMappingRow;
