// Global imports
import React, {useState, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useParams} from 'react-router';
import _ from 'lodash';
import Select from 'react-select';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import moment from 'moment';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {getLatestSnapshotDate} from 'ki-common/utils/snapshotDates';

// Local imports
import KiProgressBar from 'components/KiProgressBar';
import {showSnackbar} from 'state/actions/Snackbar';
import KiButton from 'components/KiButton';
import KiDatePicker from 'components/KiDatePicker';
import KiIconButton from 'components/KiIconButton';
import KiFontIcon from 'components/KiFontIcon';
import KiTooltip from 'components/KiTooltip';
import KiInput from 'components/KiInput';
import KiButtonSpinner from 'components/KiButtonSpinner';
import {KiSelect} from 'components/KiSelect';
import ContextIcons from 'components/ContextSidebar/icons';
import {
	fetchModels,
	fetchScenario,
	upsertScenario,
	optimizeScenario,
	updateScenarioTransferDate,
	upsertConstraintGroup,
	getConstraintGroups,
} from 'api/fundingAnalysisApi';
import {getSnapshotsByDatasetId} from 'api/datasetsApi';
import {getColumnsFromService, getMappedColumnsForDataset} from 'api/columnServiceApi';
import {fetchFundingVehicleList} from 'api/fundingVehiclesApi';
import {useMergedState} from 'utils/customHooks';
import {fetchPortfolioDates} from 'api/datasetDatesApi';
import {fetchReportGroupsByDatasetId} from 'api/reportsApi';

// Relative imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import styles from './fundingScenarioForm.theme.scss';
import ConstraintsModal from './ConstraintsModal';
import FiltersModal from './FiltersModal';
import {KiInputCell} from './KiInputCell';

const recurrenceTypeOptions = [
	{
		label: 'No Recurrence',
		value: 'none',
	},
	{
		label: 'Recurring',
		value: 'recurring',
	},
	{
		label: 'Recurring with Systematic Approvals',
		value: 'autoRecurring',
	},
];

/**
 * [FundingScenarioForm description]
 */
function FundingScenarioForm() {
	// Browser State
	const {datasetId, scenarioId} = useParams();
	const history = useHistory();
	const location = useLocation();
	const isModificationForm = location.pathname.includes('modify');

	// Redux State
	const dispatch = useDispatch();
	const isUserAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);

	// Local State
	const [showSpinner, setShowSpinner] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [isConstraintModalActive, setIsConstraintModalActive] = useState(false);
	const [isFiltersModalActive, setIsFiltersModalActive] = useState(false);
	const [activeConstraintGroup, setActiveConstraintGroup] = useState({isFVDefined: false});
	const [modelConstraintGroups, setModelConstraintGroups] = useState([]);
	const [fundingVehicleList, setFundingVehicleList] = useState([]);
	const [fundingModel, setFundingModelState] = useState({});
	const setFundingModel = val => {
		setFundingModelState(val);
	};
	const [nameError, setNameError] = useState('');
	const [areActionsLocked, setActionsLocked] = useState(false);
	const [objectiveOptions, setObjectiveOptions] = useState([]);
	const [datasetSnapshots, setDatasetSnapshots] = useState([]);
	const [datasetPortfolioDates, setDatasetPortfolioDates] = useState([]);
	const [originalScenario, setOriginalScenario] = useState({});
	const [scenario, setScenario, replaceScenario] = useMergedState({
		_id: null,
		name: '',
		fundingModelId: null,
		scenarioLimit: '',
		constraintGroupId: null,
		snapshotDate: null,
		transferDate: null,
		fvSettings: [],
		modification: {},
		filters: [],
		reportGroupId: null,
	});
	const [selectedRecurrence, setSelectedRecurrence] = useState('');
	const [reportGroups, setReportGroups] = useState([]);
	const [activeInput, setActiveInput] = useState({id: null, cursorPosition: 0});
	const [inputErrors, setInputErrors] = useState({});

	// On dataset change
	useEffect(
		() => {
			// Fetch the scenario data then fetch the related model
			const fetchScenarioData = async id => {
				setTableLoading(true);
				const fvList = await fetchFundingVehicleList(datasetId);
				setFundingVehicleList(fvList);
				const models = await fetchModels(datasetId);
				fundingAnalysisContext.setDataItem('allModels', models);
				const scenarioData = await fetchScenario(id, true);
				if (isModificationForm) {
					const modScenario = Object.assign({}, scenarioData, scenarioData.modification);
					delete modScenario.modification;
					setOriginalScenario(scenarioData);
					replaceScenario(modScenario);
				} else {
					replaceScenario(scenarioData);
				}

				const model = models.find(m => m._id === scenarioData.fundingModelId);
				setFundingModel(model);
				setTableLoading(false);
			};

			// Defaults
			// first funding model and the first constraint group
			// snapshot and transfer date to latest snapshot
			const defaultScenarioData = async (snapshots = datasetSnapshots) => {
				const fvList = await fetchFundingVehicleList(datasetId);
				setFundingVehicleList(fvList);
				const models = await fetchModels(datasetId);
				if (models.length > 0) {
					fundingAnalysisContext.setDataItem('allModels', models);
					const constraintGroups = await getConstraintGroups(models[0]._id);
					setModelConstraintGroups(constraintGroups);
					setActiveConstraintGroup(constraintGroups[0]);
					const latestSnapshot = getLatestSnapshotDate(snapshots);
					setScenario({
						fundingModelId: models[0]._id,
						constraintGroupId: constraintGroups[0]._id,
						snapshotDate: latestSnapshot,
						transferDate: latestSnapshot,
					});
					setFundingModel(models[0]);
				} else {
					setFundingModel(null); // used to show no funding model error
				}
			};

			const fetchDatasetSnapshots = async () => {
				const snapshots = await getSnapshotsByDatasetId(datasetId);
				setDatasetSnapshots(snapshots);
				const portfolioDates = await fetchPortfolioDates(datasetId);
				setDatasetPortfolioDates(portfolioDates.map(date => _.pick(date, ['groupId', 'name'])));
				if (scenarioId) {
					// Scenario selected and the dataset has loaded
					fetchScenarioData(scenarioId);
				} else {
					// No scenario selected and dataset has loaded
					// pass in snapshots to avoid race condition with set
					defaultScenarioData(snapshots);
				}
			};

			const fetchDatasetReportGroups = async () => {
				const reportGroups = await fetchReportGroupsByDatasetId(datasetId);
				setReportGroups(
					reportGroups.map(group => {
						return {
							label: group.name,
							value: group._id,
						};
					})
				);
			};

			setNameError('');
			fetchDatasetSnapshots();
			fetchDatasetReportGroups();
		},
		[datasetId]
	);

	const calculateObjectiveOptions = async () => {
		let objectiveCol;
		const mappedCols = await getMappedColumnsForDataset(datasetId);
		const {assetColumnId: assetId, _id: cohortId} = mappedCols.assetCol;

		if (fundingModel.groupBy && fundingModel.groupBy !== assetId && fundingModel.groupBy !== cohortId) {
			// Only sum aggregates (defaults to balance)
			const assetCols = await getColumnsFromService(datasetId, {
				sources: {
					includeAggregateColumns: true,
				},
				filters: {
					dataTypes: ['numeric'],
				},
			});
			setObjectiveOptions(assetCols);
			objectiveCol = assetCols.find(col => col.displayName === 'Balance' && col.calculation === 'SUM');
		} else {
			// Only numeric assets
			const assetCols = await getColumnsFromService(datasetId, {
				sources: {
					includeAssetColumns: true,
					includeAssetCalculations: true,
				},
				filters: {
					dataTypes: ['numeric'],
				},
			});
			setObjectiveOptions(assetCols);
			const balancedId = mappedCols.balanceCol.assetColumnId;
			objectiveCol = assetCols.find(col => col._id === balancedId);
		}
		return objectiveCol;
	};

	// On fundingModel change
	useEffect(
		() => {
			const onModelLoad = async () => {
				await calculateObjectiveOptions();
				const constraintGroups = await getConstraintGroups(fundingModel._id);
				setModelConstraintGroups(constraintGroups);
				if (scenario) {
					setActiveConstraintGroup(constraintGroups.find(c => c._id === scenario.constraintGroupId));
				}
			};

			const onModelSelection = async () => {
				setScenario({fundingModelId: fundingModel._id});
				const objectiveCol = await calculateObjectiveOptions();
				const constraintGroups = await getConstraintGroups(fundingModel._id);
				setModelConstraintGroups(constraintGroups);
				setActiveConstraintGroup(constraintGroups[0]);

				const fvSourceTableData = fundingModel.fvSources.map(fv => {
					const mapped = {
						fvId: fv.fvId,
						fvType: 'source',
						fvName: fv.fvName,
						objective: objectiveCol._id,
						fundingAmount: '',
						discountRate: '',
						isDrawdownInFull: false,
						fvIsUnencumbered: !!fundingVehicleList.find(f => f._id === fv.fvId).isUnencumbered,
					};
					return mapped;
				});
				const fvTargetTableData = fundingModel.fvTargets.map(fv => {
					const mapped = {
						fvId: fv.fvId,
						fvType: 'target',
						fvName: fv.fvName,
						objective: objectiveCol._id,
						fundingAmount: '',
						discountRate: '',
						isDrawdownInFull: false,
						fvIsUnencumbered: !!fundingVehicleList.find(f => f._id === fv.fvId).isUnencumbered,
					};
					return mapped;
				});
				setScenario({
					fundingModelId: fundingModel._id,
					constraintGroupId: constraintGroups[0]._id,
					fvSettings: [...fvSourceTableData, ...fvTargetTableData],
				});
			};

			if (fundingModel && fundingModel._id) {
				if (_.isEmpty(scenario.fvSettings)) {
					onModelSelection(); // new model
				} else {
					onModelLoad(); // loading scenario
				}
			}
		},
		[fundingModel]
	);

	// Handle the logic around recurrence being two booleans and not a dropdown
	useEffect(
		() => {
			let selection;
			if (scenario.isRecurring) {
				if (scenario.isAutoApproved) {
					selection = recurrenceTypeOptions.find(options => options.value === 'autoRecurring');
				} else {
					selection = recurrenceTypeOptions.find(options => options.value === 'recurring');
				}
			} else {
				selection = recurrenceTypeOptions.find(options => options.value === 'none');
			}
			setSelectedRecurrence(selection);
		},
		[scenario.isRecurring, scenario.isAutoApproved]
	);

	const updateIsUsedByModIndicator = (field, value, fvId) => {
		const fvSettings = scenario.fvSettings;
		const rowIndex = fvSettings.findIndex(fv => fv.fvId === fvId);
		fvSettings[rowIndex][field] = value;
		setScenario({fvSettings});
	};

	const getFVSetting = (fvId, field) => {
		const fvData = scenario.fvSettings.find(fv => fv.fvId === fvId);
		return fvData ? fvData[field] : '';
	};

	const setFVSetting = (fvId, field, value) => {
		const newFvSettings = _.cloneDeep(scenario.fvSettings);
		const fvIndex = newFvSettings.findIndex(fv => fv.fvId === fvId);
		newFvSettings[fvIndex][field] = value;
		setScenario({fvSettings: newFvSettings});
	};

	const selectFundingModel = fundingModel => {
		if (fundingModel._id !== scenario.fundingModelId) {
			setScenario({
				fundingModelId: fundingModel._id,
				fvSettings: [], // For the fundingModel useEffect to load new FV settings
			});
			setFundingModel(fundingModel);
			fundingAnalysisContext.setModel(fundingModel);
		}
	};

	const onFilterSave = async filtersToSave => {
		try {
			setScenario({filters: filtersToSave});
			dispatch(showSnackbar('Filters saved'));
		} catch (err) {
			dispatch(showSnackbar('Error saving Filters'));
		}
	};

	const onModalSave = async groupToSave => {
		try {
			const newGroup = await upsertConstraintGroup(groupToSave);
			setIsConstraintModalActive(false);
			const allGroups = await getConstraintGroups(groupToSave.fundingModelId);
			setModelConstraintGroups(allGroups);
			setActiveConstraintGroup(newGroup);
			setScenario({constraintGroupId: newGroup._id});
			dispatch(showSnackbar('Constraint Group saved'));
		} catch (err) {
			dispatch(showSnackbar('Error saving Constraint Group'));
		}
	};

	// Various calls will override that specific variable, if not passed it should use
	// whatever the current setting is
	const onSave = async (shouldSkipNextRecurrence = scenario.shouldSkipNextRecurrence) => {
		try {
			setShowSpinner(true);
			const scenarioToSave = _.cloneDeep(scenario);
			scenarioToSave.datasetId = datasetId;
			delete scenarioToSave.modification; // Should not have a modification on recurrence
			scenarioToSave.shouldSkipNextRecurrence = shouldSkipNextRecurrence;
			await upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario saved successfully.`));
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			//console.log('onSolve - error', err)
			fundingAnalysisContext.addError(`${scenario.name}: ${err.message}`);
			setShowSpinner(false);
		}
	};

	const onSolve = async (solveAsNew = false) => {
		const scenarioToSave = _.cloneDeep(scenario);
		scenarioToSave.datasetId = datasetId;
		delete scenarioToSave._id;
		delete scenarioToSave.modification;
		if (solveAsNew) {
			scenarioToSave.name = `${scenarioToSave.name}_resolve`;
		}
		try {
			setActionsLocked(true);
			setShowSpinner(true);
			const scenarioRes = await upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario created successfully.`));
			await optimizeScenario(scenarioRes._id);
			setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			//console.log('onSolve - error', err)
			fundingAnalysisContext.addError(`${scenario.name}: ${err.message}`);
			setShowSpinner(false);
		}
	};

	const onResolve = async () => {
		const scenarioToSave = _.cloneDeep(scenario);
		// This must be overridden with an empty object or else mongoose
		// will leave the old values there instead of deleting
		scenarioToSave.modification = {};
		try {
			setActionsLocked(true);
			setShowSpinner(true);
			await upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario re-started successfully.`));
			await optimizeScenario(scenarioToSave._id);
			setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			fundingAnalysisContext.addError(`${scenario.name}: ${err.message}`);
			setShowSpinner(false);
		}
	};

	const onModifyResolve = async () => {
		originalScenario.modification = _.cloneDeep(
			_.pick(scenario, ['constraintGroupId', 'transferDate', 'fvSettings', 'filters'])
		);
		const scenarioToSave = _.cloneDeep(originalScenario);
		try {
			setShowSpinner(true);
			await upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario updated successfully.`));
			await optimizeScenario(scenarioToSave._id);
			setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			fundingAnalysisContext.addError(`${scenario.name}: ${err.message}`);
			setShowSpinner(false);
		}
	};

	const isSolveDisabled = () => {
		/*
			disable solve if scenario exists and transferDate was changed in form
			but the save button next to it hasn't been clicked yet.
			scenarioId is from location unlike scenario.id ?
		 */
		const transferDateChangeUnsaved =
			scenarioId &&
			scenario.transferDate !== fundingAnalysisContext.allScenarios.find(s => s._id === scenarioId)?.transferDate;
		/*
			only let an unsaved transferDate change prevent solve on non-recurring scenarios
			as recurring don't have a separate save button
		 */
		if (!scenario.isRecurring && transferDateChangeUnsaved) return true;
		return !(
			scenario.name &&
			scenario.fundingModelId &&
			scenario.constraintGroupId &&
			scenario.snapshotDate &&
			scenario.transferDate &&
			!nameError &&
			!areActionsLocked &&
			Object.keys(inputErrors).length === 0
		);
	};

	const changeRecurrence = selection => {
		// Update the recurrence variable and reset the snapshot and transfer date
		const scenarioChanges = {
			transferDate: null,
			snapshotDate: null,
		};

		switch (selection.value) {
			case 'none':
				scenarioChanges.isRecurring = false;
				scenarioChanges.isAutoApproved = false;
				break;
			case 'recurring':
				scenarioChanges.isRecurring = true;
				scenarioChanges.isAutoApproved = false;
				break;
			case 'autoRecurring':
				scenarioChanges.isRecurring = true;
				scenarioChanges.isAutoApproved = true;
				break;
		}

		setScenario(scenarioChanges);
	};

	const getDateExclusions = () => {
		/* if model.includePrevAssets then exlude the transfer dates of other scenarios using this model*/
		const excludedDates = [];
		const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
		if (fundingModel && fundingModel.includePrevAssets) {
			fundingAnalysisContext.allScenarios.forEach(s => {
				if (['submitted', 'approved'].includes(s.status)) {
					excludedDates.push(s.transferDate);
				}
			});
		}
		if (scenario.snapshotDate !== latestSnapshot) {
			excludedDates.push(latestSnapshot);
		}
		return excludedDates;
	};

	const getMinDate = (snapshotDate = scenario.snapshotDate) => {
		const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
		if (snapshotDate == latestSnapshot) {
			const latest = moment(latestSnapshot, 'YYYY-MM-DD').format('YYYY-MM-DD');
			return latest;
		}
		const minDate = moment(latestSnapshot, 'YYYY-MM-DD')
			.add(1, 'days')
			.format('YYYY-MM-DD');
		return minDate;
	};

	const validateDiscountRate = value => {
		let error = false;
		if (value < 0) {
			error = 'Min value is 0';
		} else if (value > 100) {
			error = 'Max value is 100';
		} else if (value && (isNaN(value) || isNaN(parseFloat(value)))) {
			error = 'Value is not a number';
		}

		return error;
	};

	const renderDateControls = () => {
		let showSaveTransferDateButton = scenario._id && fundingAnalysisContext.status !== 'invalid';
		// Note - this only disabled the transfer date update button, a normal user can still update
		// the transfer date and click re-solve or solve as new
		if (['submitted', 'approved', 'closed'].includes(scenario.status) && !isUserAdmin) {
			showSaveTransferDateButton = false;
		}
		const baseScenario = fundingAnalysisContext.allScenarios.find(s => s._id === scenarioId);
		const isSaveTransferDateButtonDisabled = scenario.transferDate === _.get(baseScenario, 'transferDate');

		return (
			<React.Fragment>
				<div className={styles.recurringDate}>
					<span className="theme-label">Recurrence</span>
					<KiSelect
						value={selectedRecurrence}
						isDisabled={scenario._id}
						isClearable={false}
						options={recurrenceTypeOptions}
						onChange={val => changeRecurrence(val)}
					/>
				</div>

				{scenario.isRecurring && (
					<div className={styles.dateDropdown}>
						<span className="theme-label">Snapshot/Transfer Date</span>
						<KiSelect
							value={datasetPortfolioDates.filter(aDate => aDate.groupId === scenario.snapshotDate)}
							isClearable={false}
							options={datasetPortfolioDates}
							onChange={newVal =>
								setScenario({
									snapshotDate: newVal.groupId,
									transferDate: newVal.groupId,
								})
							}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.groupId}
						/>
					</div>
				)}
				{!scenario.isRecurring && (
					<React.Fragment>
						<div className={styles.datepicker}>
							<span className="theme-label">Snapshot Date</span>
							<KiDatePicker
								includeDates={datasetSnapshots.map(d => d.snapshotDate)}
								disabled={isModificationForm}
								onChange={val => {
									const shortDate = dateToShortDate(val);
									setScenario({
										snapshotDate: shortDate,
										transferDate: getMinDate(shortDate),
									});
								}}
								value={scenario.snapshotDate}
							/>
						</div>
						<div className={styles.datepicker}>
							<span className="theme-label">Transfer Date</span>
							<div style={{display: 'flex', flexDirection: 'row'}}>
								<KiDatePicker
									minDate={getMinDate()}
									excludeDates={getDateExclusions()}
									onChange={val => {
										if (!val) {
											const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
											setScenario({transferDate: latestSnapshot});
										} else {
											setScenario({transferDate: dateToShortDate(val)});
										}
									}}
									value={scenario.transferDate}
								/>
								{showSaveTransferDateButton && (
									<KiIconButton
										icon="save"
										disabled={isSaveTransferDateButtonDisabled}
										className={`${styles.saveViewIcon} list-icon-btn`}
										title="Save transfer date"
										onClick={async () => {
											try {
												setShowSpinner(true);
												await updateScenarioTransferDate(scenarioId, scenario.transferDate);
												setShowSpinner(false);
												dispatch(showSnackbar(`Transfer Date successfully updated.`));
												const scenarioData = await fetchScenario(scenarioId);
												fundingAnalysisContext.setScenario(scenarioData);
												replaceScenario(scenarioData);
												history.push(`/fundingAnalysis/${datasetId}/scenarios`);
											} catch (err) {
												setShowSpinner(false);
												fundingAnalysisContext.addError(
													`Scenario ${scenario.name}: ${err.message}`
												);
											}
										}}
									/>
								)}
							</div>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	};

	const renderControls = () => {
		// Recurring controls
		if (scenario.isRecurring) {
			return (
				<React.Fragment>
					{scenario._id &&
						!scenario.shouldSkipNextRecurrence && (
							<KiButton
								disabled={isSolveDisabled()}
								label="Cancel Next"
								type="submit"
								iconButton={false}
								primary
								onClick={() => onSave(true)}
							/>
						)}
					{scenario._id &&
						scenario.shouldSkipNextRecurrence && (
							<KiButton
								disabled={isSolveDisabled()}
								label="Resume Next"
								type="submit"
								iconButton={false}
								primary
								onClick={() => onSave(false)}
							/>
						)}
					<KiButton
						disabled={isSolveDisabled()}
						label="SAVE"
						type="submit"
						iconButton={false}
						primary
						onClick={() => onSave()}
					/>
				</React.Fragment>
			);
		}

		// Modification controls
		if (isModificationForm) {
			if (scenario._id) {
				return (
					<React.Fragment>
						<KiButton
							disabled={
								isSolveDisabled() ||
								scenario.modificationStatus === 'success' ||
								!scenario.fvSettings.map(fv => fv.isUsableByMod === true).includes(true)
							}
							label="MODIFY SCENARIO"
							type="submit"
							iconButton={false}
							primary
							onClick={() => onModifyResolve()}
						/>
					</React.Fragment>
				);
			}

			return <KiButton disabled={true} label="INVALID SCENARIO" type="submit" iconButton={false} primary />;
		}

		// Default controls
		if (scenario._id) {
			return (
				<React.Fragment>
					<KiButton
						disabled={isSolveDisabled()}
						label="SOLVE AS NEW"
						type="submit"
						iconButton={false}
						primary
						onClick={() => onSolve(true)}
					/>
					{!['submitted', 'approved', 'closed'].includes(scenario.status) && (
						<KiButton
							disabled={isSolveDisabled()}
							label="RE-SOLVE"
							type="submit"
							iconButton={false}
							primary
							onClick={() => onResolve()}
						/>
					)}
				</React.Fragment>
			);
		}

		return (
			<KiButton
				disabled={isSolveDisabled()}
				label="SOLVE"
				type="submit"
				iconButton={false}
				primary
				onClick={() => onSolve()}
			/>
		);
	};

	if (fundingModel == null) {
		return (
			<div className={styles.loaderContainer} style={{background: '#FFF'}}>
				No funding models found.
			</div>
		);
	}

	if (!scenario || !scenario.fundingModelId || !datasetSnapshots || !modelConstraintGroups || !fundingModel) {
		return (
			<div className={styles.loaderContainer} style={{background: '#FFF'}}>
				<KiProgressBar className={styles.loader} />
			</div>
		);
	}

	return (
		<React.Fragment>
			<div className={styles.root}>
				<section className={styles.nonTableOptions}>
					<div className={styles.topRowOptions}>
						<div className={styles.selectWrapper}>
							<KiInput
								type="text"
								name="name"
								label="Name"
								value={scenario.name}
								disabled={isModificationForm}
								onChange={val => {
									if (fundingAnalysisContext.allScenarios.find(m => m.name == val)) {
										setNameError('Name must be unique');
									} else {
										setNameError('');
									}
									setScenario({name: val});
								}}
								error={nameError}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<span className="theme-label">Funding Model</span>
							<Select
								classNamePrefix="aut-select"
								value={fundingAnalysisContext.allModels.find(m => m._id === scenario.fundingModelId)}
								isClearable={false}
								isDisabled={isModificationForm}
								options={fundingAnalysisContext.allModels}
								onChange={val => selectFundingModel(val)}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
						<div
							className={styles.selectWrapper}
							style={{display: 'flex', flexDirection: 'row', width: '350px'}}
						>
							<KiInput
								isNumberMasked={true}
								type="text"
								name="ScenarioLimit"
								label="Scenario Limit"
								value={scenario.scenarioLimit}
								disabled={isModificationForm}
								onChange={val => setScenario({scenarioLimit: val})}
								error={''}
							/>
							<KiTooltip
								offsets={{top: 0}}
								maxWidth={320}
								className={styles.selectWrapper}
								message={'Blank means maximum possible amount'}
							>
								<KiFontIcon className="list-icon-btn" value="info" />
							</KiTooltip>
						</div>
						<div className={styles.selectWrapper}>
							<span className="theme-label">Constraint Selection</span>
							<div style={{display: 'flex', flexDirection: 'row'}}>
								<Select
									isDisabled={!scenario.fundingModelId}
									classNamePrefix="aut-select"
									value={modelConstraintGroups.find(c => c._id === scenario.constraintGroupId)}
									isClearable={false}
									className={styles.constraintSelect}
									options={[
										{
											_id: 'new',
											constraintGroupName: 'Create New...',
											className: 'option-bold-italic',
										},
										...modelConstraintGroups,
									]}
									onChange={val => {
										if (val._id === 'new') {
											setActiveConstraintGroup({
												constraintGroupName: '',
												constraints: [],
												fundingModelId: scenario.fundingModelId,
												isFVDefined: false,
											});
											setScenario({constraintGroupId: null});
											setIsConstraintModalActive(true);
										} else {
											setActiveConstraintGroup(val);
											setScenario({constraintGroupId: val._id});
										}
									}}
									getOptionLabel={option => option.constraintGroupName}
									getOptionValue={option => option._id}
								/>
								{scenario.constraintGroupId && (
									<KiFontIcon
										className="list-icon-btn"
										value="content_copy"
										title="Copy selected constraint group"
										onClick={() => {
											const copiedConstraintItem = _.cloneDeep(activeConstraintGroup);
											delete copiedConstraintItem._id;
											copiedConstraintItem.isFVDefined = false;
											copiedConstraintItem.constraintGroupName = '';
											setActiveConstraintGroup(copiedConstraintItem);
											setIsConstraintModalActive(true);
										}}
									/>
								)}
								<div
									className={styles.filterIndicator}
									onClick={() => setIsFiltersModalActive(true)}
									alt={`${scenario.filters.length} Filters Applied`}
									title={`${scenario.filters.length} Filters Applied`}
								>
									<div className={styles.svgWrapper}>
										<ContextIcons.FiltersIcon />
									</div>
									<div className={styles.filterCount}>{scenario.filters.length}</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.middleRowOptions}>
						{renderDateControls()}
						<div className={styles.reportDropdown}>
							<span className="theme-label">Report Group</span>
							<KiSelect
								isClearable={true}
								value={reportGroups.find(c => c.value === scenario.reportGroupId) || null}
								options={reportGroups}
								onChange={select => {
									const val = select?.value || null;
									setScenario({reportGroupId: val});
								}}
							/>
						</div>
					</div>
				</section>
				<div className={styles.bottomRowTable}>
					<AbsTable
						isSortEnabled={false}
						isFilterEnabled={false}
						isLoading={tableLoading}
						showNoDataMessage={!tableLoading && scenario.fvSettings?.length === 0}
						data={scenario.fvSettings}
						columns={[
							{
								Header: '',
								id: 1,
								isVisible: isModificationForm ? true : false,
								width: 30,
								Cell: ({row}) =>
									isModificationForm &&
									row.original.fvIsUnencumbered === false &&
									(row.original.fvType === 'source' && (
										<input
											type="checkbox"
											className={'pool-checkbox'}
											name="fvCheck"
											checked={row.original.isUsableByMod}
											disabled={
												row.original.isUsableByMod === true &&
												row.original.isDrawdownInFull === true
											}
											onChange={e =>
												updateIsUsedByModIndicator(
													'isUsableByMod',
													e.target.checked,
													row.original.fvId
												)
											}
										/>
									)),
							},
							{
								Header: 'Funding Vehicle',
								accessor: 'fvName',
								id: 2,
							},
							{
								Header: 'Type',
								accessor: 'fvType',
								id: 3,
							},
							{
								Header: 'Objective',
								accessor: 'objective',
								id: 4,
								tdClassName: styles.withDropdown,
								/* eslint-disable */
								Cell: ({row}) => (
									<KiSelect
										isDisabled={isModificationForm}
										getOptionLabel={option => option.detailedDisplayName}
										getOptionValue={option => option._id}
										options={objectiveOptions}
										value={objectiveOptions.find(
											col => col._id === scenario.fvSettings[row.index].objective
										)}
										onChange={option => setFVSetting(row.original.fvId, 'objective', option._id)}
										styles={{
											container: base => ({
												...base,
												minHeight: '26px',
												height: '26px',
											}),
											control: base => ({
												...base,
												minHeight: '26px',
												height: '26px',
												borderRadius: '0px',
											}),
											valueContainer: base => ({
												...base,
												padding: '0px 8px',
											}),
											singleValue: base => ({...base, height: '14px'}),
											indicatorsContainer: base => ({
												...base,
												padding: 0,
											}),
											dropdownIndicator: base => ({
												...base,
												paddingTop: 0,
												paddingBottom: 0,
											}),
											clearIndicator: base => ({
												...base,
												paddingTop: 0,
												paddingBottom: 0,
											}),
										}}
									/>
								),
								/* eslint-enable */
							},
							{
								/* eslint-disable */
								Header: ({row, index}) => (
									<div key={index} className={styles.fundingAmount}>
										<div>Funding/Draw Amount</div>
										<KiTooltip
											maxWidth={320}
											className={styles.selectWrapper}
											message={'Blank means maximum possible amount'}
										>
											<KiFontIcon className={styles.iconStyling} value="info" />
										</KiTooltip>
									</div>
								),
								/* eslint-enable */
								accessor: 'fundingAmount',
								id: 5,
								minWidth: 160,
								/* eslint-disable */
								Cell: ({row}) => {
									const inputId = 'fundingAmount' + row.index;
									return (
										<KiInputCell
											id={inputId}
											activeInput={activeInput}
											setActiveInput={setActiveInput}
											disabled={
												row.original.fvIsUnencumbered ||
												row.original.isDrawdownInFull === true ||
												(isModificationForm && row.original.isUsableByMod === false) ||
												(isModificationForm && row.original.fvType === 'target') ||
												(!isModificationForm &&
													fundingModel.type === 'balanced_funding' &&
													row.original.fvType === 'source')
											}
											value={getFVSetting(row.original.fvId, 'fundingAmount')}
											onChange={val => setFVSetting(row.original.fvId, 'fundingAmount', val)}
										/>
									);
								},
								/* eslint-enable */
							},
							{
								Header: 'Discount Rate %',
								accessor: 'discountRate',
								id: 6,
								/* eslint-disable */
								Cell: ({row}) => {
									const inputId = 'discountRate' + row.index;
									return (
										<KiInputCell
											value={getFVSetting(row.original.fvId, 'discountRate')}
											onChange={value => {
												const error = validateDiscountRate(value);
												if (error) {
													setInputErrors(errors => ({
														...errors,
														[inputId]: error,
													}));
												} else {
													setInputErrors(errors => {
														const {[inputId]: value, ...newErrors} = errors;
														return newErrors;
													});
												}
												setFVSetting(row.original.fvId, 'discountRate', value);
											}}
											id={inputId}
											activeInput={activeInput}
											setActiveInput={setActiveInput}
											disabled={
												isModificationForm ||
												(!isModificationForm && row.original.fvIsUnencumbered) ||
												(!isModificationForm && row.original.fvType === 'source')
											}
											error={inputErrors[inputId]}
										/>
									);
								},
								/* eslint-enable */
							},
							{
								Header: 'Drawdown in Full',
								accessor: 'isDrawdownInFull',
								id: 7,
								Cell: ({row, index}) => {
									if (row.original.fvType === 'source') {
										return (
											<div key={index}>
												{row.original.isDrawdownInFull && (
													<input
														checked
														disabled={
															row.original.fvIsUnencumbered ||
															_.get(fundingModel, 'type') === 'balanced_funding' ||
															(row.original.isUsableByMod === true &&
																row.original.isDrawdownInFull === true)
														}
														onChange={e =>
															setFVSetting(
																row.original.fvId,
																'isDrawdownInFull',
																e.target.checked
															)
														}
														type="checkbox"
														className={'drawdown-checkbox'}
														name="scales"
													/>
												)}
												{!row.original.isDrawdownInFull && (
													<input
														disabled={
															row.original.fvIsUnencumbered ||
															_.get(fundingModel, 'type') === 'balanced_funding' ||
															row.original.isUsableByMod === false
														}
														onChange={e =>
															setFVSetting(
																row.original.fvId,
																'isDrawdownInFull',
																e.target.checked
															)
														}
														type="checkbox"
														className={'drawdown-checkbox'}
														name="scales"
													/>
												)}
											</div>
										);
									} else {
										return null;
									}
								},
							},
						]}
					/>
				</div>
				<div className={styles.buttons}>
					<KiButtonSpinner showSpinner={showSpinner} showButton={false} primary={false} />
					<KiButton
						label="CANCEL"
						type="submit"
						iconButton={false}
						primary
						onClick={() => history.push(`/fundingAnalysis/${datasetId}/scenarios`)}
					/>
					{renderControls()}
				</div>
			</div>
			{!!isConstraintModalActive && (
				<ConstraintsModal
					isActive={isConstraintModalActive}
					setModalActive={setIsConstraintModalActive}
					curConstraintGroup={activeConstraintGroup}
					allConstraintGroups={modelConstraintGroups}
					fundingVehicles={scenario.fvSettings.filter(fv => fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
					fundingModel={fundingModel}
					isReadOnly={false}
					onSaveFunc={onModalSave}
				/>
			)}
			{!!isFiltersModalActive && (
				<FiltersModal
					isActive={isFiltersModalActive}
					setModalActive={setIsFiltersModalActive}
					scenarioFilters={scenario.filters}
					onSaveFunc={onFilterSave}
				/>
			)}
		</React.Fragment>
	);
}

export default FundingScenarioForm;
